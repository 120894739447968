import React from 'react'
import './page.css';

const AboutMe = () => {
  return (
    <div>
      <h1>
        About Me
      </h1>
      <h2>
        List some qualifications
      </h2>
    </div>
  )
}

export default AboutMe