import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import {HandWaving, PersonSimpleRun, RocketLaunch, ChatDots} from 'phosphor-react'

const Navbar = () => {
  return (
    <div className='navbar'>
        <Link to='/' className='link'> Welcome <HandWaving /> </Link>
        <Link to='/aboutme' className='link'> About Me <PersonSimpleRun /> </Link>
        <Link to='/projects' className='link'> Projects <RocketLaunch /> </Link>
        <Link to='/sayhello' className='link'> Say Hello <ChatDots /> </Link>
    </div>
  )
}

export default Navbar