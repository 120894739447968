import React from 'react'
import './page.css';

const projects = () => {
  return (
    <div>
      <h1>
        Projects
      </h1>
      <h2>
        Here I will list some projects...
      </h2>
    </div>
  )
}

export default projects