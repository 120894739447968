import React from 'react'
import './page.css'

const Welcome = () => {
  return (
    <div>
    <h1>
      Welcome!
    </h1>
    <h2>
      I am Josh Everett, 2nd year computer science student at the University of Bristol.
      <br />Hopefully this site can act as a sort of portfolio:
      a place where you can see some of my projects along with a bit about me!
    </h2>
    </div>
  )
}

export default Welcome