import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './navbar/navbar'
import SayHello from './pages/sayhello'
import Projects from './pages/projects'
import AboutMe from './pages/aboutme'
import Welcome from './pages/welcome'

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path='/sayhello' element={<SayHello />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/aboutme' element={<AboutMe />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
